<template>
  <div>
    <div class="cotainer-standar">
      <div class="dk-container mb-30 text-center">
        <span class="d-block font-30 text-white font-weight-6 mb-40">BERITA REAL TRAVEL</span>
        <div class="container-standar mt-10 mb-10 text-left">
          <slick ref="slick" class="slickEvent hide-mobile" :options="slickEvent">
            <div class="width-100">
              <div class="event card shadow mar-5">
                <img src="https://img.youtube.com/vi/T1quxn2xbJo/0.jpg" alt />
                <div class="sub-title-content">
                  <h2
                    class="font-24 font-mobile-16 width-80 text-white"
                  >Event Organizer Taiwan Selera Anda</h2>
                  <p class="text-white width-80">Event 20 Mei 2019</p>
                </div>
                <span class="read-more-btn cursor-pointer">Read More</span>
              </div>
            </div>
            <div class="width-100">
              <div class="event card shadow mar-5">
                <img src="https://img.youtube.com/vi/T1quxn2xbJo/0.jpg" alt />
                <div class="sub-title-content">
                  <h2
                    class="font-24 font-mobile-16 width-80 text-white"
                  >Event Organizer Taiwan Selera Anda</h2>
                  <p class="text-white width-80">Event 20 Mei 2019</p>
                </div>
                <span class="read-more-btn cursor-pointer">Read More</span>
              </div>
            </div>
            <div class="width-100">
              <div class="event card shadow mar-5">
                <img src="https://img.youtube.com/vi/T1quxn2xbJo/0.jpg" alt />
                <div class="sub-title-content">
                  <h2
                    class="font-24 font-mobile-16 width-80 text-white"
                  >Event Organizer Taiwan Selera Anda</h2>
                  <p class="text-white width-80">Event 20 Mei 2019</p>
                </div>
                <span class="read-more-btn cursor-pointer">Read More</span>
              </div>
            </div>
            <div class="width-100">
              <div class="event card shadow mar-5">
                <img src="https://img.youtube.com/vi/T1quxn2xbJo/0.jpg" alt />
                <div class="sub-title-content">
                  <h2
                    class="font-24 font-mobile-16 width-80 text-white"
                  >Event Organizer Taiwan Selera Anda</h2>
                  <p class="text-white width-80">Event 20 Mei 2019</p>
                </div>
                <span class="read-more-btn cursor-pointer">Read More</span>
              </div>
            </div>
          </slick>

          <!-- <router-link to="event/detail-event">
              <figure class="snip1253">
                <div class="image">
                  <img src="https://img.youtube.com/vi/T1quxn2xbJo/0.jpg" alt="sample52" />
                </div>
                <figcaption>
                  <div class="date">
                    <span class="day">27</span>
                    <span class="month">NOV</span>
                  </div>
                  <h3>Langkah-langkah bergabung sebagai agen di REALTRAVEL</h3>
                  <p>Memperkenalkan Langkah Langkah Bergabung Sebagai Agent .....</p>
                </figcaption>
              </figure>
            </router-link>

            <router-link to="event/detail-event">
              <figure class="snip1253">
                <div class="image">
                  <img
                    src="https://storage.realtravel.co.id/news/20191022144841.jpg"
                    alt="sample52"
                  />
                </div>
                <figcaption>
                  <div class="date">
                    <span class="day">2</span>
                    <span class="month">MAY</span>
                  </div>
                  <h3>Cara Daftar Umroh Bagi WNI Yang Tinggal di ..</h3>
                  <p>Bagi WNI yang saat ini menetap di luar negeri dan bermakud ....</p>
                </figcaption>
              </figure>
            </router-link>
            <router-link to="event/detail-event">
              <figure class="snip1253">
                <div class="image">
                  <img src="https://img.youtube.com/vi/XJBibv9_FBI/0.jpg" alt="sample52" />
                </div>
                <figcaption>
                  <div class="date">
                    <span class="day">19</span>
                    <span class="month">AUG</span>
                  </div>
                  <h3>Panduan Umroh Untuk Semua Kalangan ..</h3>
                  <p>Panduan Umroh Untuk Semua kalangan tutorial lengkap video dan juga tata cara ....</p>
                </figcaption>
              </figure>
          </router-link>-->

          <!-- <div class="col-md-12 text-center mt-10">
              <span
                class="bg-primary pt-2 pb-2 btn-responsive-home opensans font-weight-6 text-white margin-auto width-30 text-center d-block cursor-pointer font-20 border-radius-5"
              >Show 1 Of 11</span>
          </div>-->
        </div>
      </div>
      <div class="container-standar mb-40">
        <div class="row">
        
          <div class="col-lg-4 col-md-6 mb-8 cursor-pointer">
              <router-link  to="event/detail-event" >
              <div class="card shadow pad-5 ">
                <img src="https://img.youtube.com/vi/T1quxn2xbJo/0.jpg" alt="">
                <div class="pad-10 d-inline-block width-100">
                  <div class="font-22 font-mobile-14  d-inine-block  text-primary">Event Organizer Selera Anda</div>
                  <span class="font-14 font-mobile-12  d-inine-block w-100 text-grey">Bandung, 20 Oktober 2019</span>
                </div>
              </div>

          </router-link>
          </div>
          <div class="col-lg-4 col-md-6 mb-8 cursor-pointer">
              <div class="card shadow pad-5 ">
                <img src="https://img.youtube.com/vi/T1quxn2xbJo/0.jpg" alt="">
                <div class="pad-10 d-inline-block width-100">
                  <div class="font-22 font-mobile-14  d-inine-block  text-primary">Event Organizer Selera Anda</div>
                  <span class="font-14 font-mobile-12  d-inine-block w-100 text-grey">Bandung, 20 Oktober 2019</span>
                </div>
              </div>
          </div>
          <div class="col-lg-4 col-md-6 mb-8 cursor-pointer">
              <div class="card shadow pad-5 ">
                <img src="https://img.youtube.com/vi/T1quxn2xbJo/0.jpg" alt="">
                <div class="pad-10 d-inline-block width-100">
                  <div class="font-22 font-mobile-14  d-inine-block  text-primary">Event Organizer Selera Anda</div>
                  <span class="font-14 font-mobile-12  d-inine-block w-100 text-grey">Bandung, 20 Oktober 2019</span>
                </div>
              </div>
          </div>
          <div class="col-md-12 text-center mt-10">
              <span
                class="bg-primary pt-2 pb-2 btn-responsive-home opensans font-weight-6 text-white margin-auto w-50 text-center d-block cursor-pointer font-20 border-radius-5"
              >
                Show More
              </span>
            </div>
          
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
import Slick from "vue-slick";

export default {
  components: {
    Slick
  },
  data() {
    return {
      slickEvent: {
        slidesToShow: 2,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false,
        arrows: false,
        centerMode: false
        // Any other options that can be got from plugin documentation
      }
    };
  },
  mounted() {
    this.$store.commit("SET_BG", true);
  }
};
</script>